<template>
  <div class="butter-content">

    <div class="page-container">
      <template v-if="trendingPage && trendingPage.fields">
        <router-link :to="{ name: 'blog-post', params: { slug: trendingPage.slug } }">
          <div class="page-header-wrapper">
            <p class="page-category">{{ trendingPage.fields.categories}}</p>
            <p class="page-title-headline">{{ trendingPage.fields.title }}</p>
            <p class="page-header">{{ trendingPage.fields.post_header }}</p>
            <p class="page-author">{{ getAuthorFullNameBySlug(trendingPage.fields.author) }}</p>
          </div>
          <p class="image-container">
            <img :src="trendingPage.fields.banner_image" alt="" srcset="">
          </p>
        </router-link>
      </template>
    </div>

    <p class="latest-title">{{ $t('latest') }}</p>

    <div class="latest-pages-container" v-if="otherPages.length">
      <div class="page-list-item" v-for="(page, index) in otherPages" :key="index">
        <template v-if="page.fields">
          <router-link :to="{ name: 'blog-post', params: { slug: page.slug } }">
            <div class="page-info-container">
              <div v-if="page.fields.banner_image" :style="{ 'background-image': 'url(' + page.fields.banner_image + ')' }" class="banner-container"></div>
              <div class="banner-container" v-else=""></div>
              <div class="title-container">
                <p class="page-category">{{ page.fields.categories}}</p>
                <p class="page-title">{{ page.fields.title | truncateIfNeeded(100)}}</p>
                <p class="page-author">{{ getAuthorFullNameBySlug(page.fields.author) }}</p>
              </div>
            </div>
          </router-link>
        </template>
      </div>
    </div>

    <div class="register-banner-container">
      <a :href="demoFormUrl" target="_blank">
        <img :src="bannerUrl" alt="">
      </a>
    </div>

  </div>
</template>

<script>
import { sortBy } from 'underscore';

import {
  butter
} from '@/buttercms';
import { parseISO, format } from 'date-fns';
import cloudinary from '@/constants/cloudinary';

export default {
  name: 'Blog',
  data() {
    return {
      title: 'Blog',
      pages: [],
      authors: [],
      categories: [],
      viewPortWidth: 0,
      urls: {},
      cloudinary: {},
    };
  },
  filters: {
    dateFormat(value) {
      return format(parseISO(value), 'MM-dd-yyyy');
    },
    truncateIfNeeded(value, truncateLimit) {
      return value.length >= truncateLimit ? `${value.substring(0, 97)}...` : value;
    },
  },
  computed: {
    trendingPage() {
      return this.pages.find((x) => x.fields.trending);
    },
    bannerUrl() {
      if(this.viewPortWidth > 768) {
        return this.cloudinary.BUSINESS_AD;
      }
      return this.cloudinary.BUSINESS_AD_MOBILE;

    },
    demoFormUrl() {
      const demoRoute = {
        name: 'enterprise',
        query: { target: 'submitContactForm' },
      };
      return this.$router.resolve(demoRoute).href;
    },
    otherPages() {
      let pages = this.pages.length ? this.pages.map((x) => {
        x.creation_date = x.fields.creation_date || x.published;
        return x;
      }) : [];
      return sortBy(pages.filter((x) => !x.fields.trending), 'creation_date').reverse();
    },
    locale() {
      return this.$route.params.locale;
    },
  },
  methods: {
    getAuthorFullNameBySlug(slug) {
      const author = this.authors.find((x) => x.slug === slug);
      return author ? `${author.first_name} ${author.last_name}` : '';
    },
    fetchPages() {
      butter.page.list('blog', {
        locale: this.locale,
        page: 1,
        page_size: 5,
      })
        .then((res) => {
          this.pages = res.data.data;
        });
    },
    fetchAuthors() {
      butter.author.list()
        .then((res) => {
          this.authors = res.data.data;
        });
    },
    fetchCategories() {
      butter.category.list({
        page: 1,
        page_size: 10,
      })
        .then((res) => {
          this.categories = res.data.data;
        });
    },
    calculateViewportWidth() {
      this.viewPortWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    },
  },
  created() {
    window.addEventListener("resize", this.calculateViewportWidth);
    this.calculateViewportWidth();
    this.fetchPages();
    this.fetchCategories();
    this.fetchAuthors();
    this.cloudinary = cloudinary;
  },
  destroyed() {
    window.removeEventListener("resize", this.calculateViewportWidth);
  },
};
</script>

<style scoped="" lang="scss">
img {
  width: 100%;
}
.butter-content {
  overflow: auto;
  text-align: left;
  padding-top: 1.5rem;
}
.page-list-item {
  display: flex;
  flex-basis: 50%;
  margin: 0 0 1.5rem;
  a {
    text-decoration: none;
    color: #161616;
  }
}
.latest-title {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}
.page-list-item {
  display: flex;
  flex-basis: 50%;
  margin: 0 0 1.5rem;
  a {
    text-decoration: none;
    color: #161616;
  }
}
.latest-title {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
}
.latest-pages-container {
  display: flex;
  flex-wrap: wrap;
}
.page-container {
  a {
    text-decoration: none;
    color: #161616;
  }
}
.image-container {
  margin: 3rem 0;
}
.page-title {
  max-width: 90%;
  font-size: 1.5rem;
  line-height: 1.4em;
  font-weight: 300;
}
.page-header {
  color: #505050;
}
.page-header-wrapper {
    width: 60%;
    &.centered {
      margin: auto;
    }
}
.page-title-headline {
  font-size: 3.5rem;
  line-height: 1.2em;
  margin: 0 0 0.875rem;
}
.page-category {
  font-weight: 700;
  text-transform: uppercase;
  color: #3c8dff;
  font-size: 0.875rem;
  margin: 0;
}
.page-author {
  color: #505050;
  font-size: 0.875rem;
  font-weight: 700;
  margin-top: 0.5rem;
}
.latest-title {
  margin-top: 3rem;
}
.banner-container {
  width: 10rem;
  min-width: 10rem;
  height: 10rem;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.page-info-container {
  display: flex;
  p {
    margin: 0 0 0.5rem;
  }
}

@include bp-xsmall {
  .back-button {
  display: block;
    a {
      color: black;
      text-decoration: none;
    }
  }
  .butter-content {
    margin: 0;
  }
  .page-header-wrapper {
    width: 90%;
    margin: 1rem 0;
  }
  .page-title-headline {
    font-size: 2.5rem;
  }
  .image-container {
    margin: 1.25rem 0rem 0;
  }
  .latest-pages-container {
    margin: 1rem 0;
    flex-direction: column;
  }
  .latest-title {
    margin-left: 0rem;
  }
  .banner-container {
    width: 100%;
    min-width: auto;
  }
  .page-info-container {
    flex-direction: column;
  }
  .title-container {
    margin-top: 1rem;
    margin-left: 0;
  }
  .register-banner-container {
    margin: 2rem 1rem;
  }
}
@include bp-small {
  .back-button {
    display: block;
    a {
      color: black;
      text-decoration: none;
    }
  }
  .butter-content {
    margin: 0;
  }
  .page-header-wrapper {
    width: 90%;
    margin: 1rem 0;
  }
  .page-title-headline {
    font-size: 2.5rem;
  }
  .image-container {
    margin: 1.25rem 0rem 0;
  }
  .latest-pages-container {
    margin: 1rem 0;
    flex-direction: column;
  }
  .latest-title {
    margin-left: 0rem;
  }
  .banner-container {
    width: 100%;
    min-width: auto;
  }
  .page-info-container {
    flex-direction: column;
  }
}

@include bp-xsmall {
  .back-button {
  display: block;
    a {
      color: black;
      text-decoration: none;
    }
  }
  .butter-content {
    padding: 2rem 0 4rem;
  }
  .page-header-wrapper {
    width: 90%;
    margin: 1rem 0;
  }
  .page-title-headline {
    font-size: 2.5rem;
  }
  .image-container {
    margin: 1.25rem 0rem 0;
  }
  .latest-pages-container {
    margin: 1rem 0;
    flex-direction: column;
  }
  .latest-title {
    margin-left: 0rem;
  }
  .banner-container {
    width: 100%;
    min-width: auto;
  }
  .page-info-container {
    flex-direction: column;
  }
  .title-container {
    margin-top: 1rem;
    margin-left: 0;
  }
  .register-banner-container {
    margin: 2rem 1rem;
  }
}
@include bp-small {
  .back-button {
    display: block;
    a {
      color: black;
      text-decoration: none;
    }
  }
  .butter-content {
    margin: 0;
  }
  .page-header-wrapper {
    width: 90%;
    margin: 1rem 0;
  }
  .page-title-headline {
    font-size: 2.5rem;
  }
  .image-container {
    margin: 1.25rem 0rem 0;
  }
  .latest-pages-container {
    margin: 1rem 0;
    flex-direction: column;
  }
  .latest-title {
    margin-left: 0rem;
  }
  .banner-container {
    width: 100%;
    min-width: auto;
  }
  .page-info-container {
    flex-direction: column;
  }
  .title-container {
    margin-top: 1rem;
    margin-left: 0;
  }
  .register-banner-container {
    margin: 2rem 1rem;
  }
}
@include bp-medium {
  .back-button {
    display: none;
  }
  .butter-content {
    margin: 0;
  }
  .page-header-wrapper {
    width: 90%;
    margin: 0;
  }
  .image-container {
    margin: 1.25rem 0rem 0;
  }
  .latest-pages-container {
    flex-direction: column;
    margin: 0;
  }
  .latest-title {
    margin-left: 0rem;
  }
  .register-banner-container {
    margin: 4rem auto;
  }
  .banner-container {
    width: 10rem;
    min-width: 10rem;
  }
  .page-info-container {
    flex-direction: row;
  }
  .title-container {
    margin-top: 0;
    margin-left: 1rem;
  }
}
@include bp-large {
 .page-header-wrapper {
    width: 60%;
    margin: 0rem;
  }
  .butter-content {
    margin: 0;
  }
  .latest-title {
    margin-left: 0rem;
  }
  .latest-pages-container {
    flex-direction: row;
    margin: 1rem 0;
  }
  .image-container {
    margin: 1.25rem 0rem 0;
  }
}

</style>
